import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';
import { YoutubeServiceService } from 'src/app/services/youtube-service.service';
import { Video } from 'src/app/models/video.model';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { FollowingService } from 'src/app/services/following.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-creatordetail',
  templateUrl: './creatordetail.component.html',
  styleUrls: ['./creatordetail.component.css']
})
export class CreatordetailComponent implements OnInit {
  creator$;
  channel$;
  creatorId;
  creatorUId;
  videoList: Video[] = [];
  lat: any;
  lng: any;

  // Form Fields
  showMForm = false;

  // Following
  isFollowing: any;
  followers: Observable<any>;
  following: Observable<any>;


  public followers$: Observable<any[]>;
  public following$: Observable<any[]>;

  safeURL: any;

  public mapStyle = [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#e9e9e9"
          },
          {
              "lightness": 17
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f5f5f5"
          },
          {
              "lightness": 20
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 17
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 29
          },
          {
              "weight": 0.2
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 18
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f5f5f5"
          },
          {
              "lightness": 21
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#dedede"
          },
          {
              "lightness": 21
          }
      ]
  },
  {
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "saturation": 36
          },
          {
              "color": "#333333"
          },
          {
              "lightness": 40
          }
      ]
  },
  {
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f2f2f2"
          },
          {
              "lightness": 19
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fefefe"
          },
          {
              "lightness": 20
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#fefefe"
          },
          {
              "lightness": 17
          },
          {
              "weight": 1.2
          }
      ]
  }
  ];



  constructor(
      public auth: AuthService,
      public db: FirestoreService,
      private route: ActivatedRoute,
      private youtubeService: YoutubeServiceService,
      private followSvc: FollowingService,
      private afs: AngularFireAuth) {
  }

  ngOnInit() {

    this.creator$ = this.route.paramMap.pipe(
      switchMap(params => {
        console.log('hello router');
        const id = params.get('id');
        this.creatorUId = id;
        console.log(id);
        this.creator$ = this.db.doc$('users/' + id).subscribe(data => {
          // Get Channnel ID of Creator
          this.creatorId = data['id'];

          // Get Location of Creator
          console.log('location of creator');
          this.lat = data['location'].latitude;
          this.lng = data['location'].longitude;

          console.log(this.lat);
          console.log(this.lng);
          // Retrieve Videos & Details of Creator
          /* Disable call for now */
          this.youtubeService.getChannel2(this.creatorId).subscribe(data2 => {
            console.log(data2);
            this.videoList = data2['items'].map(item => {
              return new Video(
                item.id.videoId,
                item.snippet.title,
                item.snippet.thumbnails.high.url,
                item.snippet.channelTitle,
                item.snippet.channelId,
                item.snippet.description);
            });
          });
          /* Disable call for now */
        });
        return this.creator$ = this.db.doc$('users/' + id);
      })

    );

   this.db.doc$(`following/${this.afs.auth.currentUser.uid}`).subscribe( data => {
    this.isFollowing = data[this.creatorUId];
   });


   this.followSvc.getFollowersTest('i1tGeSv24YhOIy9TbFYIpVczdqD2').subscribe(data => {
       console.log(data);
   });

  /* this.followSvc.getFollowers$('i1tGeSv24YhOIy9TbFYIpVczdqD2').subscribe(data => {
       console.log('this is my data');
       console.log(data);
   });
*/


  }

  

  openMessagingForm() {
    this.showMForm = !this.showMForm;
  }

  private countFollowers() {

  }

  toggleFollow() {
    if (this.isFollowing) {
        this.followSvc.unfollow(this.afs.auth.currentUser.uid, this.creatorUId);
        this.isFollowing = false;

        // console.log(this.followSvc.getFollowers(this.afs.auth.currentUser.uid));
    } else {
        this.followSvc.follow(this.afs.auth.currentUser.uid, this.creatorUId);
        this.isFollowing = true;
    }
  }


}

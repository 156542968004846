export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyC-tSTFBXxz5DxerjF8lkKL8PEe5fHT65Q',
    authDomain: 'creatormap-3078c.firebaseapp.com',
    databaseURL: 'https://creatormap-3078c.firebaseio.com',
    projectId: 'creatormap-3078c',
    storageBucket: 'creatormap-3078c.appspot.com',
    messagingSenderId: '54741892006'
  },

  googleMapsKey: 'AIzaSyDXnJg5Bsfw-OguBR18Lz0bhnaPcRJXwvo'
};

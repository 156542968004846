import { Component, OnInit } from '@angular/core';
import { MessagingService } from 'src/app/services/messaging.service';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'src/app/models/message';
//import * as $ from 'jquery';

declare var $: any;

@Component({
  selector: 'app-messaging-list',
  templateUrl: './messaging-list.component.html',
  styleUrls: ['./messaging-list.component.css']
})
export class MessagingListComponent implements OnInit {

  // loggedIn User
  loggedInUser;
  messages;
  messagearchive;

  loggedInUserId;

  archiv = false;

  userObject: object;

  replyForm: FormGroup;
  
  jQuery: any;

  

  message: Message = {
    sendFrom: {},
    sendTo: '',
    title: '',
    body: '',
    active: true,
    archived: false
  };

  constructor(
    private messagingService: MessagingService, 
    public auth: AuthService, 
    private afs: AngularFireAuth, 
    private db: FirestoreService,
    private fb: FormBuilder)
    {
      this.afs.authState.subscribe(user => {
      this.loggedInUserId = user.uid;

      this.userObject = auth.getUserObject();
      console.log(this.userObject);

      // this.messages = this.db.col$('messages', ref => ref
      // .where('sendTo', '==', '7lW8IstRAmWxCXx3vwPYXJe9Dtv2')
      // .where('archived', '==', false));
      
      // this.messagearchive = this.db.col$('messages', ref => ref
      // .where('sendTo', '==', '7lW8IstRAmWxCXx3vwPYXJe9Dtv2')
      // .where('archived', '==', false));

      this.messages = this.db.col$('messages', ref => ref
      .where('sendTo', '==', user.uid)
      .where('archived', '==', false));
      
      this.messagearchive = this.db.col$('messages', ref => ref
      .where('sendTo', '==', user.uid)
      .where('archived', '==', true));

    });
    
    

  }

  ngOnInit() {
  

   // this.db.col$('messages', ref => ref.where('user', '==', loggedInUser.))
   
    
    /*
    , ref => ref.
      where('sendTo', '==', 'i1tGeSv24YhOIy9TbFYIpVczdqD2').
      where('archived', '==', false).
      orderBy('createdAt', 'desc'));
    */
   this.replyForm = this.fb.group({
    title: ['', [Validators.required, Validators.minLength(3)]],
    textarea: ['', [Validators.required, Validators.minLength(5), Validators.min(5)]],
  });
  }


  showArchive() {
    console.log(this.archiv);
    this.archiv = !this.archiv;
  }


  archiveMessage(messageId) {
    console.log(messageId);
    this.messagingService.archiveMessage(messageId);
  }

  deleteMessage(messageId) {
    console.log('deleting this message id:' + messageId);
    this.db.delete('messages/' + messageId);
  }

  toInbox(messageId) {
    console.log(messageId);
    this.messagingService.toInbox(messageId);
  }

  submitHandler(uId, mId) {
    console.log('submit Handler');
    console.log(uId);
    this.message.title = this.replyForm.value.title;
    this.message.body = this.replyForm.value.textarea;
    this.message.sendTo = uId;
    this.message.sendFrom = this.userObject;
    console.log(this.message);
    this.messagingService.sendMessage(this.message);
    this.archiveMessage(mId);
    //$('button').attr('data-dismiss','modal');
    $('#replyModal1').modal('hide');
    $('#replyModal2').modal('hide');
  }


  replyActive(uId, mId) {
    console.log('click');
    console.log(uId);
    console.log(mId);
  }


}

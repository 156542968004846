import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.css']
})
export class FollowingComponent implements OnInit {
  creatorList$: Observable<any>;

  constructor(public db: FirestoreService, public afs: AngularFirestore) {
    this.creatorList$ = this.db.col$('users', ref => ref.where('locationSet', '==', true).orderBy('updatedAt', 'desc'));
  }

  ngOnInit() {
  }

}

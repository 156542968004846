import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { database } from 'firebase';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FollowingService {

  test: any;
  mydata: any;

  constructor(private db: FirestoreService, private afs: AngularFirestore) { }





  // Get Creators who follow me
  getFollowersTest(followerId) {
     return this.afs.collection('followers').doc(followerId).valueChanges().pipe(
      map(doc => {
        console.log(Object.keys(doc));
        if(doc === true){
          console.log('data is true');
        }else{
          console.log('data is not true');
        }
        return {mydata: doc};

      })
    );
  }



  getFollowing(followerId, followedId) {
    //return this.db.doc$(`following/7lW8IstRAmWxCXx3vwPYXJe9Dtv2`).subscribe( data => {
    //  data.
    // });
    //this.db.doc$(`following/${followerId}`).pipe(map(data =>
    //  data[followedId]
    // ).
    //));

    this.db.doc$(`following/${followerId}`).subscribe( data => {
      console.log(data[followedId]);
      return this.mydata = data[followedId];
      console.log(this.mydata);
    });
    console.log(this.mydata);
    return this.mydata;

   

  }

  public  (id: string): Observable<any> {
    console.log('test');
    if (!id) {
      //return Observable.of([]);

    }

    return this.afs
      .collection('following')
      .doc(id)
      .valueChanges().pipe(
      switchMap((f_id_array: any) => {
        const followersRefArray = [];
        for (let f_id in f_id_array) {
          if (f_id_array.hasOwnProperty(f_id) && f_id_array[f_id] === true) {
            followersRefArray.push(
              this.afs
                .collection('users')
                .doc(f_id)
                .valueChanges()
            );
          }
        }
        return followersRefArray;
      })
      );
  }




  // follow ethod
  follow(followerId: string, followedId: string) {
   // followers - Creators who follow me
    this.db.upsert1(`followers/${followedId}`, {[followerId]: true });
    this.db.upsert1(`following/${followerId}`, {[followedId]: true });
   // following - Creators who I follow
  }


  // unfollow method
  unfollow(followerId: string, followedId: string) {
    this.db.upsert1(`followers/${followedId}`, {[followerId]: false });
    this.db.upsert1(`following/${followerId}`, {[followedId]: false });
  }

}
